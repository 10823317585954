import React from 'react'
import {Link} from "gatsby"
import { useTranslation } from 'react-i18next'
import cleanUpQuotes from '../utils/cleanup-quotes'

import PostMeta from '../components/post-meta'

export default ({data, className=''}) => {
  const { t, i18n } = useTranslation()
  const localFile = data.smallImage && data.smallImage.localFile
  const smallImage = localFile
    ? data.smallImage.localFile.childImageSharp.fixed.src
    : null

  const archiveLink = data.type === 'post' ? '/articles' : '/multimedia'

  return (
    <article className={className}>
      <div className="row">
        <div className="col-lg-6">
          <Link to={`${i18n.language === 'en' ? '/en' : ''}/${archiveLink}/${data.slug}`}>
            <figure className="mb-0">
              <img src={smallImage} alt="" />
            </figure>
          </Link>
          <div className="decorator small mt-3 mr-auto"></div>
        </div>
        <div className="col-lg-6">
          <header>
            <Link to={`${i18n.language === 'en' ? '/en' : ''}/${archiveLink}/${data.slug}`}>
              <h2 dangerouslySetInnerHTML={{__html: data.title}}></h2>
            </Link>
            {
              data.type === 'post' && (
                <div className="text-muted">
                  <PostMeta author={data.author.name} date={data.date} />
                </div>
              )
            }
          </header>
            {
              data.type === 'post' && (
                <section dangerouslySetInnerHTML={{__html: cleanUpQuotes(data.excerpt)}}></section>
              )
            }
        </div>
      </div>
    </article>
  )
}
