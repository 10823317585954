import React from 'react'
import ArticleTemplate from '../pagesTemplates/articles'
import gatsbyi18Context from '../contexts/gatsby-i18n-context.js'

export default (props) => {
  const { pageContext } = props
  const { langKey: language } = pageContext

  const data = {
    language,
  }

  return (
    <gatsbyi18Context.Provider value={data}>
      <ArticleTemplate {...props} />
    </gatsbyi18Context.Provider>
  )
}

export const query = graphql`
  query {
    allWordpressPost (
      filter: {
        polylang_current_lang: {
          eq: "en_US"
        }
      }
    ) {
      edges {
        node {
          type
          id
          slug
          title
          content
          excerpt
          date
          author {
            name
          }
          smallImage: featured_media {
            localFile {
              childImageSharp {
                fixed (width: 600, height: 300) {
                  src
                }
              }
            }
          }
          largeImage: featured_media {
            localFile {
              childImageSharp {
                fixed (width: 1400, height: 500) {
                  src
                }
              }
            }
          }
        }
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        itemCount
        pageCount
        perPage
      }
    }
  }
`
