import React, {Component} from 'react'
import {graphql, Link} from 'gatsby'

import Layout from '../components/layout'
import Header from '../components/header'
import Footer from '../components/footer'
import SEO from '../components/seo'
import Article from '../components/article'
import PostMeta from '../components/post-meta'
import { withTranslation } from '../services/i18next'

class Articles extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isReady: false,
      firstArticle: null,
      restOfArticles: null
    }
  }

  componentDidMount () {
    const {
      allWordpressPost,
      // pageInfo
    } = this.props.data

    const {edges} = allWordpressPost
    const articles = edges.map(
      ({node}) => {
        return node
      }
    )

    this.setState({
      isReady: true,
      firstArticle: articles[0],
      restOfArticles: articles.slice(1)
    })
  }

  render () {
    const {isReady, firstArticle, restOfArticles} = this.state
    const { t, i18n } = this.props

    if (!isReady) {
      return (null)
    }

    return (
      <Layout className="has-header">
        <SEO title={t('articles')} />
        <Header />
        <section className="d-none d-lg-block">
          <div className="container">
            <article className="featured">
              {
                firstArticle.largeImage && firstArticle.largeImage.localFile
                  ? (
                    <figure>
                      <img src={firstArticle.largeImage.localFile.childImageSharp.fixed.src} alt="" />
                    </figure>
                  )
                  : (
                    <figure></figure>
                  )
              }
              <div className="text">
                <div className="row">
                  <div className="col-6">
                    <header>
                      <Link to={`${i18n.language === 'en' ? '/en' : ''}/articles/${firstArticle.slug}`}>
                        <h1 className="h2">
                          {firstArticle.title}
                        </h1>
                      </Link>
                      <div className="text-light">
                        <PostMeta author={firstArticle.author.name} data={firstArticle.date} />
                      </div>
                    </header>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </section>
        <section className="articles my-5">
          <div className="container">
            {
              restOfArticles.map(
                (_, i) => (
                  <Article className="mb-5" key={i.toString()} data={_} />
                )
              )
            }
          </div>
        </section>
        <Footer />
      </Layout>
    )
  }
}

export default withTranslation(Articles)
